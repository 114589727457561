.listing {
  .image-gallery-slides {
    border: 2px solid #943ffc;
    border-radius: 0px 0px 30px 0px;
    background-color: white;
  }
  .image-gallery-svg {
    color: #943ffc;
  }
  .image-gallery-svg:hover {
    color: white;
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 2px solid #943ffc;
  }
}
.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d {
  max-height: 335px !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
.gm-style .gm-style-iw-c {
  border-radius: 0px 0px 30px 30px;
  padding: 0px;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image {
  border: 3px solid #943ffc;
  border-bottom-right-radius: 40px;
  background-color: white;
}

.image-gallery-thumbnail.active {
  border: 2px solid #943ffc !important;
}

.image-gallery-thumbnail:hover {
  border: 2px solid #943ffc !important;
}

.image-gallery-content .image-gallery-slide-wrapper.left {
  @media screen and (max-width: 560px) {
    min-width: calc(100% - 45px) !important;
    width: 100% !important;
  }
}

.ipad--map {
  @media screen and (max-width: 1100px) {
    width: calc(100% - 43px) !important;

    & a {
      width: 100% !important;
      padding: 10px !important;

      & img {
        width: 100% !important;
        margin-top: 20px !important;
      }
    }
  }
}
